export const detailArr = [{
  "name": "adacayi",
  "description": "Adaçayı (Rosmarinus officinalis)",
  "image": "https://i.ibb.co/Hx6Z80S/img-i6-YCTWe-Bxg-MIcp3t9-Nh-GM.jpg",
  "botanic": "Adacayı, Akdeniz bölgesine özgü bir bitki olup, sıcak ve kuru iklimleri tercih eder, , biberigiller (Lamiaceae) familyasından, kışın yapraklarını dökmeyen ve kendine özgü kokusuyla bilinen bir bitki türüdür. Genellikle 1 ila 2 metre arasında boylanabilir ve küçük mavi veya mor renkte çiçeklere sahiptir. Yaprakları iğne şeklinde ve serttir. Bitkinin uç kısımları uçucu yağlar içerir ve bu yağlar çoğunlukla tıbbi ve kozmetik amaçlar için kullanılır.",
  "benefit": [
    "Adacayı, sindirim sistemini rahatlatıcı özelliklere sahiptir.",
    "Antioksidan içeriği sayesinde bağışıklık sistemini güçlendirir.",
    "Bazı çalışmalar, adacayının hafızayı güçlendirebileceğini öne sürmektedir.",
    "Cilt sağlığını destekleyici etkileri vardır, özellikle yağlı ciltler için faydalı olabilir.",
    "Adacayı çayı, stresi azaltmaya yardımcı olabilir ve zihinsel berraklığı artırabilir.",
    "İçerdiği anti-enflamatuar bileşikler nedeniyle eklem ağrılarını hafifletebilir.",
    "Antibakteriyel özellikleri sayesinde ağız ve diş sağlığını destekleyebilir.",
    "Adacayı yağı, saç derisini besleyerek saç sağlığını artırabilir.",
    "Sindirim sistemi üzerindeki etkileri nedeniyle sindirim sorunlarına karşı kullanılabilir.",
    "Adacayı, genel bir rahatlama ve zindelik hissi sağlayabilir."
  ],
  "danger": [
    "Hamilelerin ve emziren annelerin adacayı kullanmadan önce doktorlarına danışmaları önemlidir, çünkü yüksek dozlarda kullanım riskli olabilir.",
    "Bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle önceden küçük bir miktar denemek önerilir.",
    "Yüksek dozlarda tüketilmesi mide rahatsızlığına ve baş dönmesine neden olabilir.",
    "Adacayı uçucu yağları cilde saf halde uygulandığında yan etkilere neden olabilir, bu nedenle mutlaka seyreltilmiş halde kullanılmalıdır.",
    "Bazı kişilerde kan basıncını artırabilir, hipertansiyonu olanlar doktorlarına danışmadan kullanmamalıdır.",
    "Epilepsi gibi nörolojik bozuklukları olan bireylerin kullanmadan önce doktorlarına danışmaları önemlidir.",
    "Adacayı yağı, içerdiği bileşenler nedeniyle bazı insanlarda cilt tahrişine yol açabilir.",
    "Yüksek dozlarda alındığında mide bulantısı ve kusma gibi sindirim sorunlarına neden olabilir.",
    "Adacayı çayı veya yağının aşırı tüketimi, toksik etkilere yol açabilir, bu nedenle önerilen dozajlara dikkat edilmelidir.",
    "Adacayı, bazı ilaçlarla etkileşime girebilir, bu nedenle ilaç kullanımı olan kişilerin doktorlarına danışması önemlidir."
  ]
},

{
  "name": "anason",
  "description": "Anason (Pimpinella anisum)",
  "image":"https://i.ibb.co/xqfqfyn/img-Lwwhd2m-YQp-NG0-PYNWHJWc.jpg",
  "botanic": "Anason (Pimpinella anisum), maydanozgiller (Apiaceae) familyasından, genellikle ılıman iklim bölgelerinde yetişen bir bitkidir. Yaklaşık 50-60 cm yüksekliğe kadar büyüyebilen anason bitkisi, beyaz veya sarı renkte çiçekler açar. Tohumları genellikle baharat olarak kullanılır ve anason bitkisi çeşitli tıbbi amaçlar için de kullanılmıştır.",
  "benefit": [
    "Anason, sindirim sistemini rahatlatmaya yardımcı olabilir.",
    "Sakinleştirici özellikleri nedeniyle anason çayı stres ve kaygıyı azaltmaya yardımcı olabilir.",
    "Gaz ve şişkinlik sorunlarına karşı anasonun sindirim sistemini destekleyici etkileri bulunmaktadır.",
    "Anasonun antibakteriyel özellikleri vardır ve bazı enfeksiyonlarla mücadelede kullanılabilir.",
    "Anason tohumları ağız kokusunu gidermeye yardımcı olabilir.",
    "Anasonun antiinflamatuar özellikleri, iltihaplı durumlarla mücadelede faydalı olabilir.",
    "Anasonun içerdiği maddelerin bazıları göğüs ve solunum yolları rahatsızlıklarını hafifletmeye yardımcı olabilir.",
    "Geleneksel tıpta anason, anne sütünü artırmak için kullanılmıştır.",
    "Anasonun içerdiği yağlar cilt sağlığını destekleyici etkilere sahip olabilir.",
    "Anason çayı, uykusuzluk sorunlarını hafifletmeye yardımcı olabilir."
  ],
  "danger": [
    "Hamile kadınlar, anason kullanmadan önce mutlaka bir uzmana danışmalıdır, çünkü yüksek dozlarda anasonun düşük riskini artırabileceği düşünülmektedir.",
    "Anason alerjik reaksiyonlara neden olabilir, bu nedenle alerjik reaksiyon belirtileri görüldüğünde kullanımı derhal durdurulmalıdır.",
    "Bazı kişilerde anason tüketimi sindirim sorunlarına neden olabilir, özellikle yüksek dozlarda tüketildiğinde mide rahatsızlığına yol açabilir.",
    "Anasonun uyarıcı etkileri olabilir, bu nedenle fazla tüketimi uykusuzluğa neden olabilir.",
    "Anason, bazı ilaçlarla etkileşime girebilir, bu nedenle düzenli ilaç kullananlar doktora danışmadan anason kullanmamalıdır.",
    "Özellikle bebeklerde ve küçük çocuklarda anason kullanımı önerilmemektedir, çünkü solunum sistemi problemlerine neden olabilir.",
    "Anasonun yüksek dozlarda kullanımı cilt tahrişine yol açabilir, bu nedenle dışarıdan uygulama öncesi bir test yapılmalıdır.",
    "Anason, bazı kişilerde baş ağrısı veya baş dönmesi gibi yan etkilere neden olabilir.",
    "Anason, bazı durumlarda mide ülseri gibi gastrointestinal sorunları kötüleştirebilir.",
    "Anason yağı içeren ürünlerin gözle temasından kaçınılmalıdır, çünkü bu durum göz tahrişine yol açabilir."
  ]
},
{
  "name": "altincilek",
  "description": "Altın Çilek (Physalis peruviana)",
  "image": "https://i.ibb.co/CHvSCFF/img-v-Vs-IGa-Vl-Kc-Kh-FXJP4-Snhy.jpg",
  "botanic": "Altın Çilek, Güney Amerika kökenli olup, genellikle tropikal ve subtropikal bölgelerde yetişir, patlıcangiller (Solanaceae) familyasından bir bitki türüdür. Portakal büyüklüğünde sarı renkli meyveleri bulunur ve dış kısmı ince bir kabukla kaplıdır. İçerisinde bulunan küçük, turuncu renkli meyveler yenebilir ve tatlı bir aroma sahiptir. Altın Çilek, geleneksel tıp uygulamalarında çeşitli rahatsızlıkların tedavisinde kullanılmıştır.",
  "benefit": [
    "Altın Çilek, yüksek miktarda C vitamini içerir ve bağışıklık sistemini destekleyebilir.",
    "Meyvesinde bulunan antioksidanlar, vücudu serbest radikallerin zararlı etkilerine karşı koruyabilir.",
    "Altın Çilek, sindirim sistemini düzenleyebilir ve kabızlık sorunlarını hafifletebilir.",
    "Yapılan bazı araştırmalar, Altın Çilek ekstresinin anti-inflamatuar etkilere sahip olabileceğini öne sürmektedir.",
    "Altın Çilek, göz sağlığını destekleyebilir ve göz hastalıklarının riskini azaltmaya yardımcı olabilir.",
    "Meyvesinde bulunan fitokimyasallar, kanserle mücadelede yardımcı olabilir.",
    "Altın Çilek, kemik sağlığını destekleyebilir ve osteoporoz riskini azaltmaya yardımcı olabilir.",
    "Anti-inflamatuar özellikleri nedeniyle, Altın Çilek ekstresi, eklem iltihaplarını hafifletebilir.",
    "Altın Çilek, kan şekeri seviyelerini dengeleyebilir ve diyabet yönetimine yardımcı olabilir.",
    "Altın Çilek, cilt sağlığını destekleyebilir ve ciltteki iltihaplanmayı azaltabilir."
  ],
  "danger": [
    "Altın Çilek meyvesinin yeşil kısmı, toksik olabilir ve tüketilmemelidir.",
    "Bazı kişilerde Altın Çilek tüketimi alerjik reaksiyonlara neden olabilir.",
    "Altın Çilek tüketiminden sonra mide rahatsızlığı veya sindirim sorunları yaşayan kişilerin tüketimini sınırlamaları gerekebilir.",
    "Altın Çilek meyvesi, bazı ilaçlarla etkileşime girebilir, bu nedenle ilaç kullananlar doktorlarına danışmalıdır.",
    "Bazı durumlarda Altın Çilek tüketimi ishali tetikleyebilir, bu nedenle aşırı tüketimden kaçınılmalıdır.",
    "Hamile kadınlar ve emziren anneler Altın Çilek tüketiminden önce doktorlarına danışmalıdır.",
    "Altın Çilek tüketimi, böbrek taşı olan kişilerde risk oluşturabilir.",
    "Aşırı tüketim durumunda, Altın Çilek meyvesinin yüksek C vitamini içeriği mide rahatsızlığına neden olabilir.",
    "Altın Çilek alerjik reaksiyonlara neden olabilir, bu nedenle alerjisi olan kişilerin dikkatli olması gerekmektedir.",
    "Bazı kişilerde Altın Çilek tüketimi baş ağrısı veya migreni tetikleyebilir."
  ]
},

{
  "name": "arnika",
  "description": "Arnika (Arnica montana)",
  "image":"https://i.ibb.co/wCZQHXm/img-Uca-Oth4-F9p-YMk-Qz-Qm-Yo-Yr.jpg",
  "botanic": "Arnika, genellikle dağlık bölgelerde ve çayırlarda yetişen çok yıllık , papatyagiller (Asteraceae) familyasından bir bitki türüdür. Sarı çiçekleri ve yaprakları bulunur. Geleneksel tıpta, arnika özleri ve merhemleri genellikle yaraların iyileşmesini hızlandırmak, morlukları azaltmak ve ağrıyı hafifletmek için kullanılmıştır. Ancak arnika, doğru kullanılmadığında veya aşırı miktarda kullanıldığında ciddi yan etkilere neden olabilir.",
  "benefit": [
    "Arnika, topikal olarak uygulandığında morlukların ve şişliklerin azalmasına yardımcı olabilir.",
    "Arnika merhemleri, kas ve eklem ağrılarını hafifletebilir.",
    "Geleneksel olarak arnika, yaraların iyileşmesini hızlandırmak için kullanılmıştır.",
    "Arnika, cilt üzerinde anti-inflamatuar etkilere sahip olabilir ve ciltteki iltihaplanmayı azaltabilir.",
    "Arnika çayı, sindirim sorunlarını hafifletebilir ve sindirim sistemi sağlığını destekleyebilir.",
    "Arnika, migren ve baş ağrısını hafifletmeye yardımcı olabilir.",
    "Arnika, romatizmal rahatsızlıkların semptomlarını hafifletmeye yardımcı olabilir.",
    "Arnika, kas gerginliğini azaltabilir ve kasları rahatlatabilir.",
    "Arnika, cildin elastikiyetini artırabilir ve cilt yaşlanmasını geciktirebilir.",
    "Arnika, diş eti sağlığını destekleyebilir ve diş eti iltihabını azaltabilir."
  ],
  "danger": [
    "Arnika, içeriğindeki bazı kimyasallar nedeniyle cilt tahrişine yol açabilir.",
    "Arnika, yaralı ciltte veya açık yaralarda kullanılmamalıdır çünkü alerjik reaksiyonlara veya ciddi cilt problemlerine neden olabilir.",
    "Arnika, oral olarak alındığında toksik olabilir ve sindirim sistemi rahatsızlıklarına veya zehirlenmelere neden olabilir.",
    "Hamile kadınlar ve emziren anneler arnika kullanmadan önce doktorlarına danışmalıdır.",
    "Arnika, bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle kullanmadan önce cilt testi yapılmalıdır.",
    "Arnika, kan pıhtılaşmasını etkileyebilir, bu nedenle kan inceltici ilaçlar kullananlar veya ameliyat geçirenler arnika kullanmamalıdır.",
    "Arnika, aşırı dozda alındığında mide rahatsızlıklarına, baş dönmesine ve hatta ciddi zehirlenmelere neden olabilir.",
    "Arnika, içeriğindeki kimyasallar nedeniyle bazı kişilerde baş ağrısı veya baş dönmesine neden olabilir.",
    "Arnika, bazı kişilerde cilt reaksiyonlarına veya deri döküntülerine neden olabilir.",
    "Arnika, uzun süreli ve aşırı kullanımı karaciğer hasarına veya diğer ciddi sağlık sorunlarına neden olabilir."
  ]
},
{
  "name": "biberiye",
  "description": "Biberiye (Rosmarinus officinalis)",
  "image":"https://i.ibb.co/LgZXNJJ/img-Kj-Ycoqyxvx-St-BIDmy-Ryn-B.jpg",
  "botanic": "Biberiye, Akdeniz bölgesine özgüdür ve kuru ve sıcak iklimlerde yetişir, ballıbabagiller (Lamiaceae) familyasından bir bitki türüdür. Yaprakları iğne şeklinde ve kalıcıdır. Mor ya da mavi çiçekleri vardır ve genellikle bahçe süs bitkisi olarak yetiştirilir. Biberiye, geleneksel tıp uygulamalarında çeşitli sağlık sorunlarının tedavisinde kullanılmıştır. Özellikle yaprakları, uçucu yağlar açısından zengindir ve birçok sağlık yararı olduğuna inanılmaktadır.",
  "benefit": [
    "Biberiye, antioksidan özelliklere sahiptir ve hücreleri serbest radikallerin zararlarından koruyabilir.",
    "Biberiye çayı, sindirim sistemi sorunlarını hafifletebilir ve sindirim sağlığını destekleyebilir.",
    "Biberiye, hafıza ve zihinsel odaklanmayı artırabilir.",
    "Biberiye yağı, cilt üzerinde antiseptik ve anti-inflamatuar etkilere sahip olabilir.",
    "Biberiye, bağışıklık sistemini güçlendirebilir ve hastalıklara karşı koruyucu etki gösterebilir.",
    "Biberiye çayı, öksürük ve soğuk algınlığı semptomlarını hafifletebilir.",
    "Biberiye yağı, saç sağlığını destekleyebilir ve saç dökülmesini azaltmaya yardımcı olabilir.",
    "Biberiye, anti-inflamatuar özelliklere sahiptir ve eklem ağrılarını hafifletebilir.",
    "Biberiye, sindirim sistemi üzerinde tonik etkisiyle sindirimi kolaylaştırabilir.",
    "Biberiye, aromaterapi uygulamalarında stresi azaltmaya yardımcı olabilir."
  ],
  "danger": [
    "Biberiye yağı, yüksek konsantrasyonlarda cilde tahriş veya alerjik reaksiyonlara neden olabilir.",
    "Bazı kişilerde biberiye alerjik reaksiyonlara neden olabilir, bu nedenle kullanmadan önce cilt testi yapılmalıdır.",
    "Hamile kadınlar ve emziren anneler biberiye kullanmadan önce doktorlarına danışmalıdır.",
    "Biberiye, yüksek dozlarda alındığında mide rahatsızlığına, kusmaya veya ishale neden olabilir.",
    "Biberiye, kan basıncını etkileyebilir, bu nedenle hipertansiyonu olan kişilerin kullanımını sınırlamaları gerekebilir.",
    "Biberiye yağı, içerdiği kimyasallar nedeniyle bazı ilaçlarla etkileşime girebilir, bu nedenle ilaç kullananlar doktorlarına danışmalıdır.",
    "Bazı kişilerde biberiye alerjik reaksiyonlara neden olabilir, bu nedenle dikkatli kullanılmalıdır.",
    "Biberiye, mide ülseri gibi mide rahatsızlıkları olan kişilerde daha fazla tahrişe yol açabilir.",
    "Biberiye, epilepsi gibi nörolojik rahatsızlıkları olan kişilerde nöbetlerin artmasına neden olabilir.",
    "Biberiye, bazı kişilerde kalp çarpıntısına veya ani kan basıncı değişikliklerine neden olabilir."
  ]
},
{
  "name": "cemenotu",
  "description": "Çemenotu (Trigonella foenum-graecum)",
  "image":"https://i.ibb.co/MDWSwyL/img-UAihm2-BERlqw-Ww-KITNyp-P.jpg",
  "botanic": "Çemenotu, Akdeniz bölgesine özgüdür ve genellikle ılıman iklimlerde yetişir, baklagiller (Fabaceae) familyasından bir bitki türüdür. Yaprakları üçgenimsi ve çiçekleri beyaz veya sarı renklidir. Tohumları, baharat olarak kullanılan birçok yemeğe tat vermek için kullanılır. Geleneksel tıp uygulamalarında, çemenotu çeşitli sağlık sorunlarının tedavisinde kullanılmıştır. Özellikle tohumları, birçok sağlık yararı olduğuna inanılan bileşikler içerir.",
  "benefit": [
    "Çemenotu, kan şekeri seviyelerini dengeleyebilir ve diyabet yönetimine yardımcı olabilir.",
    "Çemenotu, sindirim sistemini düzenleyebilir ve sindirim sorunlarını hafifletebilir.",
    "Çemenotu, süt üretimini artırabilir ve emziren annelerin süt kalitesini destekleyebilir.",
    "Çemenotu, antioksidan özelliklere sahiptir ve hücreleri serbest radikallerin zararlarından koruyabilir.",
    "Çemenotu, cilt sağlığını destekleyebilir ve ciltteki iltihaplanmayı azaltabilir.",
    "Çemenotu, kolesterol seviyelerini düşürebilir ve kalp sağlığını destekleyebilir.",
    "Çemenotu, öksürük ve boğaz ağrısını hafifletebilir ve solunum yollarını rahatlatabilir.",
    "Çemenotu, bağışıklık sistemini güçlendirebilir ve hastalıklara karşı koruyucu etki gösterebilir.",
    "Çemenotu, libido artırıcı etkilere sahip olabilir ve cinsel performansı destekleyebilir.",
    "Çemenotu, anti-inflamatuar özelliklere sahiptir ve eklem ağrılarını hafifletebilir."
  ],
  "danger": [
    "Çemenotu, bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle kullanmadan önce cilt testi yapılmalıdır.",
    "Çemenotu, yüksek dozlarda alındığında mide rahatsızlığına veya ishale neden olabilir.",
    "Hamile kadınlar ve emziren anneler çemenotu kullanmadan önce doktorlarına danışmalıdır.",
    "Çemenotu, bazı kişilerde kan şekeri seviyelerini düşürebilir, bu nedenle diyabet ilacı kullananlar dikkatli olmalıdır.",
    "Çemenotu, yüksek miktarda alındığında kan pıhtılaşmasını etkileyebilir, bu nedenle kan inceltici ilaçlar kullananlar dikkatli olmalıdır.",
    "Çemenotu, çocuklarda veya bebeklerde kullanılmamalıdır çünkü olumsuz yan etkilere neden olabilir.",
    "Çemenotu, mide veya bağırsak sorunları olan kişilerde daha fazla mide rahatsızlığına neden olabilir.",
    "Çemenotu, özellikle yüksek dozlarda alındığında kan basıncını etkileyebilir, bu nedenle hipertansiyonu olan kişiler kullanmadan önce doktorlarına danışmalıdır.",
    "Çemenotu, bazı kişilerde baş ağrısı veya baş dönmesine neden olabilir.",
    "Çemenotu, aşırı tüketildiğinde ciltte kızarıklık, kaşıntı veya döküntülere neden olabilir."
  ]
},
{
  "name": "corekotu",
  "description": "Çörekotu (Nigella sativa)",
  "image":"https://i.ibb.co/Jkd7Vjb/img-79-Nf-Twkfg-IPc-Gp-Kh-Ku0f6.jpg",
  "botanic": "Çörekotu, Orta Doğu ve Güney Asya kökenlidir ve genellikle Akdeniz bölgesinde yetişir, çanakkaleğiller (Ranunculaceae) familyasından bir bitki türüdür. Küçük beyaz veya mavi çiçekleri vardır ve meyveleri siyah renklidir. Tohumları, baharat olarak kullanılan ve geleneksel tıpta çeşitli rahatsızlıkların tedavisinde kullanılan birçok bileşiği içerir.",
  "benefit": [
    "Çörekotu, antioksidan özelliklere sahiptir ve hücreleri serbest radikallerin zararlarından koruyabilir.",
    "Çörekotu yağı, cilt üzerinde anti-inflamatuar etkilere sahip olabilir ve ciltteki iltihaplanmayı azaltabilir.",
    "Çörekotu, astım semptomlarını hafifletebilir ve solunum yollarını rahatlatabilir.",
    "Çörekotu, sindirim sistemini düzenleyebilir ve sindirim sorunlarını hafifletebilir.",
    "Çörekotu, bağışıklık sistemini güçlendirebilir ve hastalıklara karşı koruyucu etki gösterebilir.",
    "Çörekotu, kan şekeri seviyelerini dengeleyebilir ve diyabet yönetimine yardımcı olabilir.",
    "Çörekotu, anti-inflamatuar özelliklere sahiptir ve eklem ağrılarını hafifletebilir.",
    "Çörekotu, cilt sağlığını destekleyebilir ve ciltteki akne ve sivilce gibi sorunları hafifletebilir.",
    "Çörekotu, saç sağlığını destekleyebilir ve saç dökülmesini azaltmaya yardımcı olabilir.",
    "Çörekotu, migren ve baş ağrısını hafifletebilir ve baş ağrısını azaltabilir."
  ],
  "danger": [
    "Çörekotu, yüksek dozlarda alındığında mide rahatsızlığına veya ishale neden olabilir.",
    "Hamile kadınlar ve emziren anneler çörekotu kullanmadan önce doktorlarına danışmalıdır.",
    "Çörekotu, bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle kullanmadan önce cilt testi yapılmalıdır.",
    "Çörekotu, bazı kişilerde kan şekeri seviyelerini düşürebilir, bu nedenle diyabet ilacı kullananlar dikkatli olmalıdır.",
    "Çörekotu, yüksek miktarda alındığında kan pıhtılaşmasını etkileyebilir, bu nedenle kan inceltici ilaçlar kullananlar dikkatli olmalıdır.",
    "Çörekotu, çocuklarda veya bebeklerde kullanılmamalıdır çünkü olumsuz yan etkilere neden olabilir.",
    "Çörekotu, özellikle yüksek dozlarda alındığında mide rahatsızlığına veya kusmaya neden olabilir.",
    "Çörekotu, bazı kişilerde baş ağrısı veya baş dönmesine neden olabilir.",
    "Çörekotu, ciltte kızarıklık, kaşıntı veya döküntülere neden olabilir.",
    "Çörekotu, aşırı tüketildiğinde karaciğer hasarına veya diğer ciddi sağlık sorunlarına neden olabilir."
  ]
},
{
  "name": "defneyapragi",
  "description": "Defne Yaprağı (Laurus nobilis)",
  "image":"https://i.ibb.co/FHdbhjr/img-JS5-Vb-Q9-Mlr6-Ez-Y1-PGpa-FN.jpg",
  "botanic": "Defne yaprağı, Akdeniz bölgesine özgüdür ve genellikle kuru ve sıcak iklimlerde yetişir, defnegiller (Lauraceae) familyasından bir bitki türüdür. Yaprakları derin yeşil renkte ve parlak yapraklardır. Çiçekleri küçük ve sarımsı yeşil renklidir. Defne yaprağı, geleneksel tıpta çeşitli sağlık sorunlarının tedavisinde kullanılmıştır ve ayrıca baharat olarak da kullanılmaktadır.",
  "benefit": [
    "Defne yaprağı, sindirim sistemi sorunlarını hafifletebilir ve sindirim sağlığını destekleyebilir.",
    "Defne yaprağı, antioksidan özelliklere sahiptir ve hücreleri serbest radikallerin zararlarından koruyabilir.",
    "Defne yaprağı, solunum yollarını rahatlatabilir ve öksürük semptomlarını hafifletebilir.",
    "Defne yaprağı, cilt sağlığını destekleyebilir ve ciltteki iltihaplanmayı azaltabilir.",
    "Defne yaprağı, stresi azaltabilir ve rahatlamaya yardımcı olabilir.",
    "Defne yaprağı, bağışıklık sistemini güçlendirebilir ve hastalıklara karşı koruyucu etki gösterebilir.",
    "Defne yaprağı, romatizmal rahatsızlıkların semptomlarını hafifletebilir ve eklem ağrılarını azaltabilir.",
    "Defne yaprağı, sindirim sistemini düzenleyebilir ve kabızlık sorunlarını hafifletebilir.",
    "Defne yaprağı, anti-inflamatuar özelliklere sahiptir ve iltihaplanmayı azaltabilir.",
    "Defne yaprağı, saç sağlığını destekleyebilir ve saç dökülmesini azaltmaya yardımcı olabilir."
  ],
  "danger": [
    "Defne yaprağı, yüksek dozlarda alındığında mide rahatsızlığına veya kusmaya neden olabilir.",
    "Hamile kadınlar ve emziren anneler defne yaprağı kullanmadan önce doktorlarına danışmalıdır.",
    "Defne yaprağı, bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle kullanmadan önce cilt testi yapılmalıdır.",
    "Defne yaprağı, yüksek miktarda alındığında sinir sistemi üzerinde etkiler yapabilir ve baş dönmesine neden olabilir.",
    "Defne yaprağı, mide ülseri gibi mide rahatsızlıkları olan kişilerde daha fazla tahrişe yol açabilir.",
    "Defne yaprağı, özellikle yüksek dozlarda alındığında kalp çarpıntısı gibi kardiyovasküler etkilere neden olabilir.",
    "Defne yaprağı, çocuklarda veya bebeklerde kullanılmamalıdır çünkü olumsuz yan etkilere neden olabilir.",
    "Defne yaprağı, bazı kişilerde baş ağrısı veya baş dönmesine neden olabilir.",
    "Defne yaprağı, aşırı tüketildiğinde ciltte kızarıklık, kaşıntı veya döküntülere neden olabilir.",
    "Defne yaprağı, uzun süreli ve aşırı kullanımı karaciğer hasarına veya diğer ciddi sağlık sorunlarına neden olabilir."
  ]
},
{
  "name": "ebegumeci",
  "description": "Ebegümeci (Althaea officinalis)",
  "image": "https://i.ibb.co/s6wRnmH/img-Ox-Bq9n-LPSDb-IROJs-NFp-PJ.jpg",
  "botanic": "Ebegümeci, ılıman iklimlerde yetişen çok yıllık otsu bir bitkidir, ebegümecigiller (Malvaceae) familyasından bir bitki türüdür. Yaprakları kalp şeklinde ve tüylüdür. Çiçekleri pembe veya mor renklidir ve oldukça gösterişlidir. Ebegümeci kökleri ve yaprakları geleneksel tıpta çeşitli rahatsızlıkların tedavisinde kullanılmıştır.",
  "benefit": [
    "Ebegümeci, boğaz ağrısını hafifletebilir ve boğazı yatıştırabilir.",
    "Ebegümeci, öksürük semptomlarını hafifletebilir ve balgam söktürücü etkiye sahip olabilir.",
    "Ebegümeci, sindirim sistemini düzenleyebilir ve sindirim sorunlarını hafifletebilir.",
    "Ebegümeci, bağırsakları yumuşatabilir ve kabızlık sorunlarını hafifletebilir.",
    "Ebegümeci, cilt üzerinde anti-inflamatuar etkilere sahip olabilir ve ciltteki iltihaplanmayı azaltabilir.",
    "Ebegümeci, sindirim sistemi üzerinde tonik etkisiyle sindirimi kolaylaştırabilir.",
    "Ebegümeci, özellikle göğüs ve boğaz rahatsızlıklarında balgam söktürücü etkileriyle kullanılabilir.",
    "Ebegümeci, mideyi yatıştırabilir ve mide rahatsızlıklarını hafifletebilir.",
    "Ebegümeci, özellikle yaraların iyileşmesini hızlandırmak için cilt bakımında kullanılabilir.",
    "Ebegümeci, özellikle göğüs ve boğaz rahatsızlıklarında rahatlatıcı etkilere sahiptir."
  ],
  "danger": [
    "Ebegümeci, bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle kullanmadan önce cilt testi yapılmalıdır.",
    "Ebegümeci, yüksek dozlarda alındığında mide rahatsızlığına veya ishale neden olabilir.",
    "Hamile kadınlar ve emziren anneler ebegümeci kullanmadan önce doktorlarına danışmalıdır.",
    "Ebegümeci, diyabet ilaçları veya kan inceltici ilaçlar gibi ilaçlarla etkileşime girebilir, bu nedenle ilaç kullananlar dikkatli olmalıdır.",
    "Ebegümeci, çocuklarda veya bebeklerde kullanılmamalıdır çünkü olumsuz yan etkilere neden olabilir.",
    "Ebegümeci, bazı kişilerde mide rahatsızlıklarını artırabilir ve mide ülseri gibi durumları kötüleştirebilir.",
    "Ebegümeci, özellikle yüksek dozlarda alındığında baş dönmesine veya baş ağrısına neden olabilir.",
    "Ebegümeci, mide kanamasına veya ciddi mide problemlerine neden olabilir.",
    "Ebegümeci, aşırı tüketildiğinde ciltte kızarıklık, kaşıntı veya döküntülere neden olabilir.",
    "Ebegümeci, kalp hastalığı olan kişilerde kalp ritim bozukluklarına neden olabilir."
  ]
},
{
  "name": "enginar",
  "description": "Enginar (Cynara scolymus)",
  "image":"https://i.ibb.co/Cvm3JXK/img-MHUa-I5a2-YGq2-Pb-Gfk2-Mm-O.jpg",
   "botanic": "Enginar (Cynara scolymus), papatyagiller (Asteraceae) familyasından, Akdeniz kökenli bir sebzedir. Yüksek lif ve antioksidan içeriğiyle bilinir. Genellikle çiçek tomurcuğu olarak tüketilir ve çeşitli sağlık faydalarıyla ilişkilendirilir.",
  "benefit": [
    "Enginar, sindirim sistemini destekleyerek kabızlık sorunlarını hafifletebilir.",
    "Yüksek lif içeriği sayesinde enginar, sindirim sağlığını iyileştirebilir ve kolon kanseri riskini azaltabilir.",
    "Enginar, karaciğer sağlığını destekleyebilir ve karaciğer fonksiyonlarını iyileştirebilir.",
    "Antioksidan bakımından zengin olan enginar, vücudu serbest radikallerin zararlı etkilerine karşı koruyabilir.",
    "Enginar, kan şekerinin dengelenmesine yardımcı olabilir ve diyabet riskini azaltabilir.",
    "Enginarın içerdiği bileşikler, kolestrol seviyelerini düşürebilir ve kalp sağlığını iyileştirebilir.",
    "Enginarın idrar söktürücü etkileri vardır, böylece vücutta fazla sıvının atılmasına yardımcı olabilir.",
    "Enginar, vücuttaki iltihaplanmayı azaltabilir ve romatoid artrit gibi iltihabi hastalıkların semptomlarını hafifletebilir.",
    "Enginar, antioksidan özellikleri sayesinde cilt sağlığını destekleyebilir ve yaşlanma belirtilerini azaltabilir.",
    "Enginar yapraklarından elde edilen ekstraktlar, böbrek sağlığını korumaya yardımcı olabilir ve böbrek taşı oluşumunu engelleyebilir."
  ],
  "danger": [
    "Enginar bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle özellikle enginara alerjisi olanların dikkatli olması gerekir.",
    "Enginar yaprakları, bazı kişilerde ishal veya mide rahatsızlıklarına neden olabilir, bu nedenle aşırı tüketimden kaçınılmalıdır.",
    "Enginar, pıhtılaşma sürecini etkileyebilir, bu nedenle kan sulandırıcı ilaçlar kullananlar enginar tüketirken dikkatli olmalıdır.",
    "Enginar, bazı kişilerde gaz ve şişkinlik gibi sindirim sorunlarına yol açabilir.",
    "Enginar yaprakları, bazı kişilerde deri döküntüleri veya kaşıntı gibi cilt reaksiyonlarına neden olabilir.",
    "Enginar çiçek tomurcukları, tiroid bezinin fonksiyonlarını etkileyebilir, bu nedenle tiroid sorunu olanlar enginarı aşırı tüketmemelidir.",
    "Enginar, bazı kişilerde alerjik astım semptomlarını kötüleştirebilir.",
    "Enginar çiçek tomurcukları, hamilelik sırasında aşırı miktarda tüketilmemelidir, çünkü bazı bileşiklerin rahim kasılmalarına neden olabileceği düşünülmektedir.",
    "Enginarın idrar söktürücü etkileri, aşırı kullanım durumunda elektrolit dengesizliklerine neden olabilir.",
    "Enginarın antioksidan özellikleri, bazı kişilerde bağışıklık sistemi tepkilerini etkileyebilir ve otoimmün hastalıkları kötüleştirebilir."
  ]
},
{
  "name": "fundayaprak",
  "description": "Funda Yaprağı (Melissa officinalis)",
  "image":"https://i.ibb.co/SfgBvJG/img-PLy-KO7c3-Ek4b6y-B8-Ww-CVk.jpg",
  "botanic": "Funda Yaprağı (Melissa officinalis), ballıbabagiller (Lamiaceae) familyasından, hoş kokulu bir bitkidir. Genellikle çay olarak tüketilir ve tıbbi amaçlar için de kullanılır. Funda yaprağı, geleneksel tıpta sakinleştirici, antidepresan ve sindirim sorunlarına karşı kullanılmıştır.",
  "benefit": [
    "Funda yaprağı çayı, sakinleştirici özellikleriyle bilinir ve stresi azaltabilir.",
    "Funda yaprağı çayı, uykusuzluk sorunlarını hafifletebilir ve daha iyi bir uyku kalitesi sağlayabilir.",
    "Antidepresan etkileri nedeniyle funda yaprağı, hafif depresyon semptomlarını hafifletebilir.",
    "Funda yaprağı çayı, mide bulantısı ve sindirim sorunlarına karşı etkili olabilir.",
    "Antioksidan içeriği sayesinde funda yaprağı, vücudu serbest radikallerin zararlı etkilerine karşı koruyabilir.",
    "Funda yaprağı çayı, baş ağrısı ve migren gibi baş ağrılarını hafifletebilir.",
    "Funda yaprağı çayı, hafif kas gevşetici özelliklere sahiptir ve kas ağrılarını azaltabilir.",
    "Funda yaprağı, bağışıklık sistemini destekleyebilir ve hastalıklara karşı vücut direncini artırabilir.",
    "Funda yaprağının içerdiği bazı bileşikler, kan şekeri seviyelerini düzenleyebilir ve diyabet riskini azaltabilir.",
    "Funda yaprağı çayı, sindirim sistemi üzerinde rahatlatıcı etkilere sahiptir ve sindirim sorunlarını hafifletebilir."
  ],
  "danger": [
    "Funda yaprağı, bazı kişilerde alerjik reaksiyonlara neden olabilir, bu nedenle ilk kez tüketmeden önce dikkatli olunmalıdır.",
    "Funda yaprağı çayının aşırı tüketimi, mide bulantısı ve ishale neden olabilir.",
    "Funda yaprağı, bazı kişilerde uykulu hissetmeye neden olabilir, bu nedenle araç kullanmadan önce dikkatli olunmalıdır.",
    "Funda yaprağı, tiroit hastalığı olan kişilerde tiroit fonksiyonlarını etkileyebilir, bu nedenle aşırı tüketimden kaçınılmalıdır.",
    "Funda yaprağı çayı, hamilelik ve emzirme dönemindeki kadınlar tarafından kullanılmamalıdır çünkü etkileri hakkında yeterli bilgi bulunmamaktadır.",
    "Funda yaprağı çayının aşırı tüketimi, kan şekeri seviyelerini etkileyebilir, bu nedenle diyabet hastaları dikkatli olmalıdır.",
    "Funda yaprağı çayı, bazı kişilerde baş dönmesine veya halsizliğe neden olabilir.",
    "Funda yaprağı, bazı kişilerde mide yanması veya hazımsızlık gibi sindirim sorunlarını kötüleştirebilir.",
    "Funda yaprağı, bazı kişilerde cilt reaksiyonlarına neden olabilir, bu nedenle ciltte kızarıklık, kaşıntı veya döküntü görülmesi durumunda kullanımı durdurulmalıdır.",
    "Funda yaprağı çayının aşırı tüketimi, kalp çarpıntısı gibi kalp rahatsızlıklarına neden olabilir."
  ]
}

]

/* Anason (Pimpinella anisum) bitkisi için
name: bitkinin türkçe karakter içermeyen bitişik tek kelimesi küçük harfler ile string
description: bitkinin tam adı türkçe adı ve latince adı içeren string
botanic: tıbbi kökeninin anlatan 10 cümle içeren string
benefit: bitkinin sağlığa fyadalarını anlatan 10 elamanlı bir array
danger:bitkinin sağlığa zararlarını anlatan 10 elamanlı bir array

oluşturacak şekilde bir json dön */