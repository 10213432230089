import { SELECT_PRODUCT } from "./action";

const initialState = {
  products: [], // Ürün listesi
  selectedProduct: null, // Seçilen ürün
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    // Diğer case'leri buraya ekleyebilirsiniz
    default:
      return state;
  }
};

export default productsReducer;
