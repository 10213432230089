import { initializeApp } from "firebase/app";
import { getFirestore} from "@firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyB0Vj4_bil4qP4h7Lg1QMZ_ZdGdcD21EDo",
  authDomain: "emrull.firebaseapp.com",
  projectId: "emrull",
  storageBucket: "emrull.appspot.com",
  messagingSenderId: "291052979478",
  appId: "1:291052979478:web:e166a2e152894ee2d13c37"
};


const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)