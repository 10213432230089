import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function ActionAreaCard(props) {
  const upHeight = props.upHeight;
  const downHeight = props.mainInfo?.length ? 65: 0;
  const [respWidth, setRespWidth] = React.useState(
    document.getElementById("cardTitle")?.offsetWidth || 400
  );
  React.useEffect(() => {
    const handleResize = () => {
      setRespWidth(document.getElementById("cardTitle")?.offsetWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateFontSize(width) {
    const baseFontSize = 16; // Başlangıç font boyutu (px)
    const baseWidth = 150; // Başlangıç genişlik değeri (px)

    const fontSize = baseFontSize * (width / baseWidth);

    return fontSize + "px"; // Font boyutunu piksel olarak döndürür
  }

  const fontSize = calculateFontSize(respWidth || 400);
  const fontSubSize = calculateFontSize(
    document.getElementById("cardSubtitle")?.offsetHeight || 200
  );

  React.useEffect(() => {
    const handleResize = () => {
      setRespWidth(document.getElementById("cardTitle")?.offsetWidth);
    };

    handleResize();
  }, []);

  return (
    <Card key={props.keyValue} sx={{ width: "100%", height: upHeight }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height={upHeight - downHeight}
          image={props.image}
          alt={props.description}
        />

        <div
          id="cardTitle"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: downHeight,
            textAlign: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              textAlign: "center",
              padding: 20,
              fontSize: fontSize,
            }}
          >
            {" "}
            {props.mainInfo}
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
}
