import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import ReactGA from "react-ga4";
import { thunk } from "redux-thunk";
import routes from "./routes";
import rootReducer from "./management/store";

ReactGA.initialize("G-R665WVLTRZ");
ReactGA.send({ hitType: "pageview", page: window?.location?.pathname + window?.location?.search, title: "home" });

const store = createStore(rootReducer, applyMiddleware(thunk)); // redux-thunk middleware'ini kullanarak store oluşturun

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Routes>{routes}</Routes>
    </Router>
  </Provider>,
  document.getElementById("app")
);
