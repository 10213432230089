// AxiosPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { detailArr } from '../datas/detail';

const AxiosPage = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
       // const response = await axios.get('https://mocki.io/v1/5777882c-b3c3-4501-892f-1974c2c5ac54');
        setData(detailArr);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return { data, error, loading };
};

export default AxiosPage;
