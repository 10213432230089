import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import "./list.css";
import AxiosPage from "../axios/detaiAxios";
import ReactGA from "react-ga4";
import ActionAreaCard from "../common/card";

const List = ({ products }) => {
  const { name } = useParams();
  const { data, error, loading } = AxiosPage();

  const items = products
    ? products
    : data?.find((item) => item.name == name?.toLocaleLowerCase()) || [];

  ReactGA.event({
    category: items.name,
    action: "visit",
    label: items.name,
  });

  function convertDesc(str) {
    const firstWord = str.split("(")[0];
    const index = firstWord.indexOf("(");
    const result = index !== -1 ? firstWord.slice(0, index) : firstWord;

    return result;
  }

  function convertSub(str) {
    const firstWord = str.split("(")[1];
    const index = firstWord.indexOf("(");
    const result = index !== -1 ? firstWord.slice(0, index) : firstWord;

    return result.split(")")[0]?.toLocaleLowerCase();
  }

  return (
    <div>
      <div className="list-container">
        <h1 style={{color:"purple"}}>{convertDesc(items.description)}</h1>

        <ActionAreaCard upHeight={550} image={items.image} />
        <h2 style={{color:"purple"}}>{convertSub(items.description)}</h2>
        <h2>Tıbbi Kökeni</h2>

        <div className="text-item text-indent"> {items.botanic} </div>

        <h2> Sağlığa faydaları</h2>
        {items.benefit?.map((item, index) => (
          <li className="list-item text-item" key={index}>
            {" "}
            {item}
          </li>
        ))}
        <div style={{ margin: 10, font: "message-box", color: "red" }}>
          Ancak, bu sağlık faydalarının tamamının bilimsel olarak kanıtlanmış
          olmadığını unutmamak önemlidir. Herhangi bir tıbbi durumu tedavi etmek
          veya önlemek için kullanmadan önce bir sağlık uzmanına danışmak
          önemlidir.
        </div>

        <h2>Sağlığa zararları</h2>
        {items.danger?.map((danger, index) => (
          <li className="list-item text-item" key={index}>
            {" "}
            {danger}
          </li>
        ))}

        <div style={{ margin: 10, font: "message-box", color: "red" }}>
          Yine de bu etkilerin kişiden kişiye değişebileceğini ve herkesin
          farklı şekillerde tepki verebileceğini unutmamak önemlidir. Bu
          nedenle, tüketmeden önce bir sağlık uzmanına danışmak önemlidir.
        </div>
      </div>

      <h1 className="list-container" style={{marginTop:20, color:"purple"}}>SAĞLIKLI GÜNLER DİLERİZ</h1>
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.selectedProduct,
});

export default connect(mapStateToProps)(List);
