import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { selectProduct } from "../management/action";
import { detailArr } from "../datas/detail";
import "./app.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { SizeMe } from "react-sizeme";
import ActionAreaCard from "../common/card";
import nextId from "react-id-generator";
import AxiosPage from "../axios/detaiAxios";
import ReactGA from "react-ga4";
import FlagInfo from "./flagInfo";

const App = ({ selectProduct }) => {
  const navigate = useNavigate();
  const { data, error, loading } = AxiosPage();
  ReactGA.event({ category: "home", action: "home", label: "home" });

  const handleProductClick = (product) => {
    selectProduct(product);
    navigate(`/${product.name}`);
  };

  const getDescriptionInfo = (description) => {
    const match = description.match(/^(.*?) \((.*?)\)$/);
    if (match) {
      const [, mainInfo, additionalInfo] = match;
      return { mainInfo, additionalInfo };
    } else {
      return { mainInfo: "", additionalInfo: "" };
    }
  };

  const getColumns = (width) => {
    if (width >= 900) {
      return 5;
    } else if (width >= 750) {
      return 4;
    } else if (width >= 350) {
      return 3;
    } else {
      return 2;
    }
  };

  const randomValue = () => {
    const values = [140, 170, 200, 230, 250];
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
  };

  return (
    <SizeMe>
      {({ size }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 style={{fontFamily: 'Lato, sans-serif'}}>Doğanın</h1>
          <h2 style={{fontFamily: 'Lato, sans-serif'}}>İyileştirici</h2>
          <h2 style={{fontFamily: 'Lato, sans-serif'}}>Gücü</h2>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 3, 750: 4, 900: 5 }}
          >
            <Masonry
              gutter={"11px"}
              columnsCount={getColumns(size.width)}
              style={{ width: size.width - 40}}
            >
              {data?.map((drawer, index) => {
                const { mainInfo, additionalInfo } = getDescriptionInfo(
                  drawer.description
                );
                const randomHeight = randomValue();
                const uniqId = nextId();

                return (
                  <div key={uniqId} onClick={() => handleProductClick(drawer)}>
                    <ActionAreaCard
                      mainInfo={mainInfo}
                      additionalInfo={additionalInfo}
                      upHeight={randomHeight}
                      keyValue={uniqId}
                      image={drawer.image}
                      description={drawer.description}
                    />
                  </div>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
          <div style={{marginTop:10}}>
          <FlagInfo/>
          </div>
          
        </div>
      )}
    </SizeMe>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectProduct: (product) => dispatch(selectProduct(product)),
});

export default connect(null, mapDispatchToProps)(App);
