import { useEffect, useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  increment,
  query,
  where,
} from "firebase/firestore";

async function addUserToFirestore(timezone) {
  const usersCollectionRef = collection(db, "users");

  try {
    const docRef = await addDoc(usersCollectionRef, {
      timezone: timezone,
      entryCount: 1,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

function FlagInfo() {
  const [users, setUsers] = useState([]);
  const userCollectionRef = collection(db, "users");

  const getUsers = async () => {
    try {
      const querySnapshot = await getDocs(userCollectionRef);
      const userData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUsers(userData);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (users.some((item) => item.timezone?.includes(userTimezone))) {
        const incrementEntryCount = async (userId) => {
          const userRef = doc(db, "users", userId);
          await updateDoc(userRef, {
            entryCount: increment(1),
          });
        };

        const usersQuery = query(
          userCollectionRef,
          where("timezone", "==", userTimezone)
        );
        getDocs(usersQuery).then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const firstUser = querySnapshot.docs[0];
            const userId = firstUser.id;
            incrementEntryCount(userId);
          }
        });
      } else {
        addUserToFirestore(userTimezone);
      }
    }
  }, [users]);

  return (
    <div>
      <div style={{ color: "purple" }}>Visits  by  TimeZone</div>
      {users?.map((user) => {
        if (user.timezone !== "UTC")
          return (
            <div key={user.id}>
              {" "}
              {user.timezone} : {user.entryCount}{" "}
            </div>
          );
      })}
    </div>
  );
}

export default FlagInfo;
