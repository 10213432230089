import React from 'react';
import { Route } from 'react-router-dom';

import App from './components/app';
import List from './components/list';

const routes = (
  <>
    <Route path="/" element={<App />} />
    <Route path="/:name" element={<List />} />
  </>
);

export default routes;